const CAMERA_RADIUS = 3
const CAMERA_RADIUS_MIN = 1
const CAMERA_RADIUS_MAX = 14

export class CameraManager{  
    constructor(appManager){
        this.appManager = appManager
        this.camera = this.createArcCamera()
    }

    createArcCamera() {
        let camera = new BABYLON.ArcRotateCamera("camera", 3 * Math.PI / 4, Math.PI / 4, 4, BABYLON.Vector3.Zero(), this.appManager.scene)
        camera.minZ = 0.01
        camera.allowUpsideDown = false
        camera.wheelPrecision = 150
        camera.pinchDeltaPercentage = 0.0001
        camera.useNaturalPinchZoom = false
        camera.pinchPrecision = 0.0001
        camera.panningSensibility = 0
        camera.radius = CAMERA_RADIUS
        camera.lowerRadiusLimit = CAMERA_RADIUS_MIN
        camera.upperRadiusLimit = CAMERA_RADIUS_MAX
        camera.attachControl(this.appManager.htmlElements.canvas, true)
        camera.rebuildAnglesAndRadius()
        this.setUpCameraAutoRotation(camera)
        
        return camera;
    }

    setUpCameraAutoRotation(camera){
        camera.useAutoRotationBehavior = true
        camera.autoRotationBehavior.idleRotationSpeed = 0.1
        camera.autoRotationBehavior.idleRotationWaitTime = 2000
        camera.autoRotationBehavior.idleRotationSpinupTime = 2000
        camera.autoRotationBehavior.zoomStopsAnimation = true
        camera.useAutoRotationBehavior = false

    }
}