import {MDCSnackbar} from '@material/snackbar';
import {MDCDialog} from '@material/dialog';

export class HTMLElements{

    constructor(){
        this.canvas
        this.loadingScreen
        this.procedureButtons = []

        this.initialise()
    }

    initialise(){
        this.canvas = document.getElementById("renderCanvas")
        this.loadingScreen = document.getElementById("loadingScreen")
        
        this.helpButton = document.getElementById("help-btn")
        this.helpPopup = new MDCDialog(document.getElementById('helpPopup'))
        this.snackBar = new MDCSnackbar(document.querySelector('.mdc-snackbar'))

        this.setupMenuBarButtons(this.helpPopup)
    }

    setupMenuBarButtons(helpPopup){
        this.helpButton.onclick = function(){helpPopup.open();}
    }
}