const BABYLON = require('babylonjs');
import './main.css'
import 'babylonjs-loaders'
import { AppManager } from "./Scripts/Managers/appManager"
import { HTMLElements } from "./Scripts/Objects/htmlElements"

let engine, scene, appManager, htmlElements

function renderTour(){
    htmlElements = new HTMLElements()
    engine = new BABYLON.Engine(document.getElementById("renderCanvas"),true, { stencil: true }, true)
    
    /* fetch(path)
    .then(response => response.json())
    .then(data => startRendering(data)) */
    startRendering("")
}

async function startRendering(data){
    scene = await createScene(data)
    engine.runRenderLoop(function (){
        scene.render() 
    }) 
}

var createScene = async function (data) {
    var scene = new BABYLON.Scene(engine)
    scene.clearColor = new BABYLON.Color3(35/255,30/255,45/255)
    appManager = new AppManager(engine, scene, data, htmlElements)
    return scene
}

window.addEventListener("resize", function () {
    engine.resize()
  })
  
window.addEventListener('DOMContentLoaded', (event) => {
    renderTour()
})
