import {MDCRipple} from '@material/ripple';

export class VRManager{  
    constructor(appManager){
        this.appManager = appManager
        this.hitMarker
        this.modelPlaced = false
        this.resetModelButton

        this.checkDeviceCompatibility()
    }

    async initialise() {
        this.xr = await this.appManager.scene.createDefaultXRExperienceAsync({
            uiOptions: {
                sessionMode: "immersive-vr",
                referenceSpaceType: "local-floor"
            }
        });
        this.fm = this.xr.baseExperience.featuresManager;
    }

    checkDeviceCompatibility(){
        this.resetModelButton = document.querySelector('.mdc-fab')
        this.initialise()
        this.viewInVRButton = document.getElementById("view-in-VR-btn")
        this.viewInVRButton.onclick = () => this.toggleSession()
    }

    async toggleSession(){
        if(this.xr.baseExperience.state != BABYLON.WebXRState.IN_XR){
            try {
                await this.xr.baseExperience.enterXRAsync("immersive-vr", "local-floor", this.xr.renderTarget);
                this.appManager.qrScanner.qrScannerButton.classList.add("hide")
                document.getElementById("uploadLabel").classList.add("hide")
            }
            catch(err) {
                this.supported = false;
                this.appManager.htmlElements.snackBar.open(); 
                this.appManager.htmlElements.snackBar.labelText = "VR Mode is not supported on your device."
                return;
            }
        }else{
            try {
                await this.xr.baseExperience.exitXRAsync();
                this.appManager.qrScanner.qrScannerButton.classList.remove("hide")
                document.getElementById("uploadLabel").classList.remove("hide")
            }
            catch(err) {
                this.supported = false;
                return;
            }
        }
    }
}