const GUI = require('babylonjs-gui');

const TITLE_TEXT_ANIMATION_TOTAL_FRAMES = 20;

export class Hotspot{  

    constructor(hotspotManager, position, content){
        
        this.hotspotManager = hotspotManager
        this.appManager = hotspotManager.appManager
        this.position = position
        this.hotspotObject
        this.element
        this.content = content
        this.isTextVisible = false

        this.createHotspot()
    }

    createHotspot(){
        this.hotspot = this.createHotspotGUI("title");
        this.hotspotMesh = this.createHotspotMesh(this.hotspot);
        this.animateWidgetScaleTo(0.5);

        this.hotspot.onPointerEnterObservable.add(() => {
            this.animateWidgetScaleTo(1);
            this.animateWidgetTitleTextVisible(true)
        })

        this.hotspot.onPointerOutObservable.add(() => {
            this.animateWidgetScaleTo(0.5);
            this.animateWidgetTitleTextVisible(false)
        })
        this.hotspot.onPointerClickObservable.add(() => {
            this.animateWidgetScaleTo(0.5);
            this.appManager.mediaPlayer.loadMedia({url:this.content.url});
        })
        this.appManager.raycastSelector.highlightLayer.addExcludedMesh(this.hotspotMesh)
    }

    createHotspotMesh(hotspotGUI){
        let hotspotMesh = BABYLON.MeshBuilder.CreatePlane("hotspotMesh", {width:1, height:1}, this.appManager.scene);
        hotspotMesh.billboardMode = BABYLON.Mesh.BILLBOARDMODE_ALL;
        hotspotMesh.position.x = this.position.x;
        hotspotMesh.position.y = this.position.y;
        hotspotMesh.position.z = this.position.z;

        let hotspotADT = GUI.AdvancedDynamicTexture.CreateForMesh(hotspotMesh);
        hotspotADT.renderScale = 1;
        hotspotADT.addControl(hotspotGUI);

        return hotspotMesh
    }

    createHotspotGUI(title) {
        let hotspot = new GUI.Ellipse("hotspot", "");
        hotspot.width = "1000px";
        hotspot.height = "1000px";
        hotspot.paddingBottomInPixels = 220;
        hotspot.paddingTopInPixels = 220;
        hotspot.paddingLeftInPixels = 220;
        hotspot.paddingRightInPixels = 220;
        hotspot.color = "#FFFFFFFF"
        hotspot.background = "#CCCCCC5B"
        hotspot.thickness = 100;
        hotspot.shadowColor = "#000000FF"
        hotspot.shadowBlur = 97.00;
        hotspot.clipContent = false;
        hotspot.clipChildren = false;


        hotspot.addControl(this.createIcon());
        if(this.content.name !== null) {
            this.hotspotText = this.createText();
            hotspot.addControl(this.hotspotText);
        }

        return hotspot
    }

    createText(){
        var text = new GUI.TextBlock("text",this.content.name);
        text.fontSizeInPixels = 140;
        text.color = "white";
        text.paddingTopInPixels = 500;
        text.widthInPixels = 1000;
        text.textWrapping = true;
        text.resizeToFit = true;
        text.shadowBlur = 35;
        text.scaleX = 0.8;
        text.isVisible = true;
        text.alpha = 0;

        //TODO: revise gui animations
        text.animations = [];
        let alphaAnimation = new BABYLON.Animation("alphaAnimation", "alpha", 60, BABYLON.Animation.ANIMATIONTYPE_FLOAT, 0);
        let alphaAnimationKeys = [
            { frame: 0, value: 0 }, 
            { frame: TITLE_TEXT_ANIMATION_TOTAL_FRAMES / 2, value: 1 },
            { frame: TITLE_TEXT_ANIMATION_TOTAL_FRAMES, value: 0 }
        ];
        alphaAnimation.setKeys(alphaAnimationKeys);
        text.animations.push(alphaAnimation);

        let positionAnimation = new BABYLON.Animation("positionAnimation", "paddingTopInPixels", 60, BABYLON.Animation.ANIMATIONTYPE_FLOAT, 0);
        let animationShowPositionKeys = [
            { frame: 0, value: 650 },
            { frame: TITLE_TEXT_ANIMATION_TOTAL_FRAMES / 2, value: 730 }, 
            { frame: TITLE_TEXT_ANIMATION_TOTAL_FRAMES, value: 650 }
        ];
        positionAnimation.setKeys(animationShowPositionKeys);
        text.animations.push(positionAnimation); 

        return text;
    }

    createIcon(){
        var icon = new BABYLON.GUI.Image("icon", this.getIconURL());
        icon.width = "240px";
        icon.height = "240px";
        return icon;
    }
    getIconURL(){
        if(this.content.url.includes(".mp4"))
            return "/textures/icons/icon_video.svg";
        else if(this.content.url.includes(".pdf"))
            return "/textures/icons/icon_document.svg";
        else if(this.content.url.includes(".png"))
            return "/textures/icons/icon_image.svg";
        else
            return "/textures/icons/icon_text.svg";
    }

    animateWidgetScaleTo(scale){
        let ease = new BABYLON.CubicEase();
        ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEOUT);

        BABYLON.Animation.CreateAndStartAnimation('WidgetScaleX', this.hotspotMesh, 'scaling.x', 60, 10, this.hotspotMesh.scaling.x, scale, 0, ease);
        BABYLON.Animation.CreateAndStartAnimation('WidgetScaleY', this.hotspotMesh, 'scaling.y', 60, 10, this.hotspotMesh.scaling.y, scale, 0, ease);
    }
    animateWidgetTitleTextVisible(isVisible) {
        if(this.hotspotText) {
            if(isVisible && !this.isTextVisible) {
                this.isTextVisible = true
                this.appManager.scene.beginAnimation(
                    this.hotspotText, 
                    0, 
                    TITLE_TEXT_ANIMATION_TOTAL_FRAMES / 2, 
                    false
                );
            }
            else if(!isVisible && this.isTextVisible) {
                this.isTextVisible = false
                this.appManager.scene.beginAnimation(
                    this.hotspotText, 
                    TITLE_TEXT_ANIMATION_TOTAL_FRAMES / 2, 
                    TITLE_TEXT_ANIMATION_TOTAL_FRAMES, 
                    false
                );
            }
        }
    }

    dispose(){
        this.hotspotMesh.dispose()
    }

    /* createHotspot(){
        this.hotspotObject = new BABYLON.Mesh("dummy", this.appManager.scene)
        this.hotspotObject.position = this.position
        const hotspotElement = document.createElement("button")
        hotspotElement.classList.add("hotspot")
        document.body.appendChild(hotspotElement)
        this.element = hotspotElement
        hotspotElement.style.display = "none"
        const self = this
        if(this.hotspotManager.hotspots.length === 0){
            hotspotElement.addEventListener("click", function() {
                self.appManager.productManager.animationGroup.play()
            });
        }else{
            hotspotElement.addEventListener("click", function() {
                self.appManager.productManager.animationGroup.reset()
            });
        }
       
        

        this.appManager.scene.registerAfterRender(() => { 
            if(this.hotspotObject !== undefined){
                const pos = BABYLON.Vector3.Project(
                    new BABYLON.Vector3(this.hotspotObject.position.x.toFixed(2),this.hotspotObject.position.y.toFixed(2),this.hotspotObject.position.z.toFixed(2)),
                    BABYLON.Matrix.IdentityReadOnly,
                    this.appManager.scene.getTransformMatrix(),
                    this.appManager.cameraManager.camera.viewport.toGlobal(
                        this.appManager.engine.getRenderingCanvas().clientWidth,//engine.getRenderWidth(),
                        this.appManager.engine.getRenderingCanvas().clientHeight,//engine.getRenderHeight(),
                    ),
                )
                hotspotElement.style.left = pos.x.toFixed(0)+'px'
                hotspotElement.style.top = pos.y.toFixed(0)+'px'
            }
        })  
    } */
}