import {MDCSwitch} from '@material/switch';
import {MDCDialog} from '@material/dialog';

export class Options{

    constructor(appManager){
        this.appManager = appManager

        this.initialise()
    }

    initialise(){
        this.optionsButton = document.getElementById("options-btn")
        const optionsPopup = new MDCDialog(document.getElementById('optionsPopup'))
        this.optionsPopup = optionsPopup
        this.optionsButton.onclick = function(){optionsPopup.open();}

        this.setupOptions()
    }

    setupOptions(){
        this.setupOptionCameraRotation()
        this.setupOptionBackgroundColour()
        this.setupOptionGrid()
        this.setupOptionGizmo()
        this.setupOptionHotspots()
    }

    setupOptionCameraRotation(){
        const switchElement = document.getElementById('optionCameraRotation')
        const switchControl = new MDCSwitch(switchElement);

        switchElement.onclick = () => this.appManager.cameraManager.camera.useAutoRotationBehavior = switchControl.selected;
    }

    setupOptionBackgroundColour(){
        const switchElement = document.getElementById('optionBackgroundColour')
        const switchControl = new MDCSwitch(switchElement);

        switchElement.onclick = () => !switchControl.selected ? this.appManager.scene.clearColor = new BABYLON.Color3(30/255,30/255,30/255) : this.appManager.scene.clearColor = new BABYLON.Color3(210/255,210/255,210/255);
    }

    setupOptionGrid(){
        const switchElement = document.getElementById('optionGrid')
        const switchControl = new MDCSwitch(switchElement);

        switchElement.onclick = () =>  this.appManager.grid.toggleGrid()
    }

    setupOptionGizmo(){
        const switchElement = document.getElementById('optionGizmo')
        const switchControl = new MDCSwitch(switchElement);

        switchElement.onclick = () =>  this.appManager.modelManager.toggleGizmoz()
    }

    setupOptionHotspots(){
        const switchElement = document.getElementById('optionHotspots')
        const switchControl = new MDCSwitch(switchElement);

        //switchElement.onclick = () =>  this.appManager.productManager.hotspotManager.toggleHotspots()
    }
}