import { Model } from "../Objects/model";

export class ModelManager{  

    constructor(appManager){
        this.appManager = appManager
        this.modelPaths = [
            {"url": "/textures/models/puh.glb", "transform":{"positionX":0,"positionY":0,"positionZ":0,"rotationX":0,"rotationY":0,"rotationZ":0}},
            {"url": "/textures/models/puh.glb", "transform":{"positionX":0,"positionY":1,"positionZ":0,"rotationX":0,"rotationY":0,"rotationZ":0}}
        ]
        this.models = []
        this.root = new BABYLON.TransformNode("modelRoot");
        this.lastModelId = 0

        this.loadModels()
    }

    async loadNewModel(content){
        await this.disposeModels()
        this.modelPaths = [content]
        this.loadModels()
    }

    async loadModels(){
        let self = this
        this.modelPaths.forEach(function(m) {
            self.models.push(new Model(self.appManager, m, self.lastModelId))
            self.lastModelId++
        });
    }

    async disposeModels(){
        this.models.forEach(function(m) {
            m.disposeModel()
        });
        this.models = []
    }

    toggleGizmoz(){
        this.models.forEach(function(m) {
            m.toggleGizmo()
        });
    }
}