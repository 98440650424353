export class Model{  

    constructor(appManager, modelPath, modelId){
        this.appManager = appManager
        this.modelPath = modelPath
        this.root
        this.animationGroups = []
        this.id = modelId
        //this.animationControls = new AnimationControls(this)

        this.loadProductModel(modelPath.url, false)
        this.setupGizmo()
    }

    async loadProductModel(modelPath, isUploadedFile){
        let models
        let self = this
        this.animationGroups = []
        if(isUploadedFile){ 
            models = await BABYLON.SceneLoader.ImportMeshAsync("","",modelPath)
        }
        else{
            models = await BABYLON.SceneLoader.ImportMeshAsync("",modelPath,"")
        }
        models.meshes[0].metadata = this.id

        if(models.animationGroups.length > 0){
            models.animationGroups.forEach(function(g) {
                //self.setUpAnimationGroup(g)
            })
        }
        this.animationGroups = models.animationGroups
        models.meshes[0].position = new BABYLON.Vector3(this.modelPath.transform.positionX,this.modelPath.transform.positionY,this.modelPath.transform.positionZ)
        this.root = models.meshes[0]// BABYLON.Mesh.MergeMeshes(models.meshes.filter(m => m.getTotalVertices() > 0), true, true);
        this.appManager.scene.stopAnimation(models.meshes[0])
        this.models = models 
        document.getElementById("loadingScreen").style.display = "none"
        this.product = models
        this.gizmo.attachedMesh = this.root;
        this.checkForCameras()
        this.toggleGizmo()
    }

    disposeModel(){
        if(this.models){
            this.models.meshes.forEach(function(m) {
                m.dispose();
            });
        }
        this.product = null
        this.models = null
        this.root = null
        this.animationGroup = null
        this.gizmo.attachedMesh = null

        this.slider = null
        document.getElementById('bottomBar').classList.add("hide")
    }

    setupGizmo(){
        const utilLayer = new BABYLON.UtilityLayerRenderer(this.appManager.scene);

        this.gizmo = new BABYLON.PositionGizmo(utilLayer);

        // Keep the gizmo fixed to world rotation
        this.gizmo.updateGizmoRotationToMatchAttachedMesh = false;
        this.gizmo.updateGizmoPositionToMatchAttachedMesh = true;
    }
    toggleGizmo(){
        this.gizmo.attachedMesh = !this.gizmo.attachedMesh ? this.root : null
    }

    selectModel(){
        //if(hasAnimation){
            //show animation controls this.animationControls.resetAnimation(this.animationGroups[0])
        //}
        //else{
            //hide animation controls
        //}
    }

    checkForCameras(){
        console.log(this.modelPath, this.appManager.scene.cameras)
    }
}