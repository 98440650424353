
export class ContentOption{

    constructor(appManager, content, baseElement, listElement){
        this.appManager = appManager
        this.content = content
        this.baseElement = baseElement
        this.listElement = listElement
        this.element 
        this.name = content.url
        this.url = content.url
        this.button
        this.initialise()
    }

    initialise(){
        this.createElement()
    }

    createElement(){
        this.element = this.baseElement.cloneNode(true)
        this.element.classList.remove("hide")
        this.listElement.appendChild(this.element)
        this.element.childNodes.forEach((child) => {
            if(child.innerHTML === "name"){
                if(this.name.includes('resources/')){
                    var fields = this.name.split('resources/');
                    fields = fields[1].split('?raw=true')
                    child.innerHTML = fields[0].replace(/%20/g, " ");
                }else{
                    child.innerHTML = this.name
                }
            }
        })
        this.element.onclick = () => {this.appManager.mediaPlayer.loadMedia(this.content); this.appManager.contentDrawer.drawer.open = false}
    }

    /* setupButton(){
        var self = this
        this.element.childNodes.forEach((child) => {
            child.childNodes.forEach((subchild) => {
                if(subchild.id == "openContentButton"){
                    subchild.classList.remove("hide")
                    
                    subchild.onclick = () => {
                        self.appManager.productManager.loadProductModel(this.content.url, true); 
                        self.appManager.productManager.disposeModel()  
                        this.appManager.qrScanner.qrResultPopup.close()
                    }
                }
            })
        })
    } */

    disposeElement(){
        this.content = null
        this.contentOptionsList = null
        this.baseElement = null
        this.element.innerHTML = ""
        this.element.remove()
        this.id = null
        this.name = null
        this.button = null
    }
}