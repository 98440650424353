import {MDCRipple} from '@material/ripple';

export class XRManager{  
    constructor(appManager){
        this.appManager = appManager
        this.hitMarker
        this.modelPlaced = false
        this.resetModelButton

        this.checkDeviceCompatibility()
        this.setupAnchorButton()
    }

    async initialise() {
        
        let uiElement = document.getElementById('body');

        this.xr = await this.appManager.scene.createDefaultXRExperienceAsync({
            uiOptions: {
                sessionMode: "immersive-ar",
                referenceSpaceType: "unbounded"
            },
            optionalFeatures: ['dom-overlay'],
            domOverlay: { root: uiElement }
        });
        this.fm = this.xr.baseExperience.featuresManager;

        this.xrTest = this.fm.enableFeature(BABYLON.WebXRHitTest.Name, "latest");
        this.anchors = this.fm.enableFeature(BABYLON.WebXRAnchorSystem.Name, 'latest');
        this.dom = this.fm.enableFeature(BABYLON.WebXRDomOverlay.Name, 'latest');
        this.fm.enableFeature(BABYLON.WebXRDomOverlay, "latest", { element: uiElement }, undefined, false);

        this.xrBackgroundRemover = this.fm.enableFeature(BABYLON.WebXRBackgroundRemover.Name);

        
        this.hitMarker = this.setupHitMarker()
        this.hitTest;
        this.xrTest.onHitTestResultObservable.add((results) => {
            if (results.length && !this.modelPlaced) {
                this.hitMarker.isVisible = true;
                this.hitTest = results[0];
                this.hitTest.transformationMatrix.decompose(undefined, this.hitMarker.rotationQuaternion, this.hitMarker.position);
            } else {
                this.hitMarker.isVisible = false;
                this.hitTest = undefined;
            }
        });

        this.appManager.scene.onPointerDown = (evt, pickInfo) => {
            if (this.hitTest && this.anchors && this.xr.baseExperience.state === BABYLON.WebXRState.IN_XR && !this.modelPlaced) {
                this.anchors.addAnchorPointUsingHitTestResultAsync(this.hitTest);
                this.placeModel()
            }
        }
    }

    setupHitMarker(){
        const myMaterial = new BABYLON.StandardMaterial("myMaterial", this.appManager.scene);
        myMaterial.diffuseColor = new BABYLON.Color3(1, 1, 1);
        myMaterial.emissiveColor = new BABYLON.Color3(1, 1, 1);

        const hitMarker = BABYLON.MeshBuilder.CreateTorus('marker', { diameter: 0.15, thickness: 0.05, tessellation: 32 });
        hitMarker.scaling.y = 0.1
        hitMarker.material = myMaterial;
        hitMarker.isVisible = false;
        hitMarker.rotationQuaternion = new BABYLON.Quaternion();
        return hitMarker
    }

    checkDeviceCompatibility(){
        this.resetModelButton = document.querySelector('.mdc-fab')
        this.initialise()
        this.viewInARButton = document.getElementById("view-in-AR-btn")
        this.viewInARButton.onclick = () => this.toggleSession()
        this.viewInARButton.classList.remove("hide")

    }

    async toggleSession(){
        if(this.xr.baseExperience.state != BABYLON.WebXRState.IN_XR){
            try {
                await this.xr.baseExperience.enterXRAsync("immersive-ar", "unbounded", this.xr.renderTarget);
                this.appManager.qrScanner.qrScannerButton.classList.add("hide")
                document.getElementById("uploadLabel").classList.add("hide")
                this.hideModel()
            }
            catch(err) {
                this.supported = false;
                this.appManager.htmlElements.snackBar.open(); 
                this.appManager.htmlElements.snackBar.labelText = "AR Mode is not supported on your device."
                return;
            }
        }else{
            try {
                await this.xr.baseExperience.exitXRAsync();
                this.appManager.qrScanner.qrScannerButton.classList.remove("hide")
                document.getElementById("uploadLabel").classList.remove("hide")
                this.hitMarker.isVisible = false;
            }
            catch(err) {
                this.supported = false;
                return;
            }
            this.resetModel();
        }
        
    }

    placeModel(){
        this.hitTest.transformationMatrix.decompose(undefined, this.appManager.productManager.root.rotationQuaternion, this.appManager.productManager.root.position)
        this.appManager.productManager.root.scaling = new BABYLON.Vector3(1,1,-1)
        this.modelPlaced = true
        this.resetModelButton.classList.remove("hide")
    }
    resetModel(){
        this.appManager.productManager.root.position = new BABYLON.Vector3(0,0,0)
        this.appManager.productManager.root.scaling = new BABYLON.Vector3(1,1,-1)
        this.modelPlaced = false
    }
    hideModel(){
        this.appManager.productManager.root.position = new BABYLON.Vector3(0,0,0)
        this.appManager.productManager.root.scaling = new BABYLON.Vector3(0,0,0)
        this.resetModelButton.classList.add("hide")
        this.modelPlaced = false
    }

    setupAnchorButton(){
        const fabRipple = new MDCRipple(document.querySelector('.mdc-fab'));
        this.resetModelButton.onclick = () => this.hideModel()
    }
}