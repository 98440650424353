export class RaycastSelector{  

    constructor(appManager){
        this.appManager = appManager
        this.currentSelected;

        this.intialise()
    }

    intialise(){
        var highlightLayer = new BABYLON.HighlightLayer("highlightLayer01", this.appManager.scene);
        highlightLayer.blurHorizontalSize = 0.3
        highlightLayer.blurVerticalSize = 0.3
        highlightLayer.innerGlow = false;
        highlightLayer.outerGlow = true;
        this.highlightLayer = highlightLayer

        /* function allDescendants (node) {
            if(node._children == null)
                return
            for (var i = 0; i < node._children.length; i++) {
              var child = node._children[i];
              allDescendants(child);
              console.log(child)
              highlightLayer.addMesh(child, BABYLON.Color3.Purple());
            }
        } */
        
        this.appManager.scene.onPointerPick = (evt, pickInfo) => {
            if (pickInfo.hit) {
                let mesh = pickInfo.pickedMesh;
                while (mesh.parent !== null) {
                  mesh = mesh.parent;
                }

                if(mesh.metadata != null){
                    this.highlightLayer.removeAllMeshes()
                    if(this.currentSelected == mesh.metadata){
                        this.currentSelected = null
                        this.appManager.animationControls.resetAnimation()
                        return
                    }
                    
                    this.currentSelected = mesh.metadata
                    this.appManager.modelManager.models.forEach((m) =>{
                        if(mesh.metadata == m.id){
                            this.appManager.animationControls.resetAnimation(m.animationGroups[0])
                            m.models.meshes.forEach((x) => {
                                try{
                                    this.highlightLayer.addMesh(x, BABYLON.Color3.White());
                                }catch{
                                    console.log()
                                }
                            })
                        }
                    }) 
                }
            }
               // mesh = BABYLON.Mesh.MergeMeshes(mesh.meshes.filter(m => m.getTotalVertices() > 0), true, true);
                //this.highlightLayer.addMesh(mesh, BABYLON.Color3.Green());
               // mesh.children.forEach(m => this.highlightLayer.addMesh(m, BABYLON.Color3.Black()));
        }
    }
}