export class XRManagerBlippar{  
    constructor(appManager){
        this.appManager = appManager
        this.hitMarker
        this.modelPlaced = false
        this.resetModelButton

        this.checkDeviceCompatibility()
    }

    async initialise() {
        let uiElement = document.getElementById('body');
        var sphere = BABYLON.MeshBuilder.CreateSphere("sphere", {diameter: 1, segments: 32}, this.appManager.scene);
        let webarMarkerMesh1 = new BABYLON.Mesh("webarMarkerMesh1", this.appManager.scene);
        let webarMarkerMesh2 = new BABYLON.Mesh("webarMarkerMesh2", this.appManager.scene);
        this.webarMarkerMeshMap = [];
        this.webarMarkerMeshMap.push({ markerId: "ede0123b-e919-456c-8617-3546f2c9365f", markerMesh: webarMarkerMesh1 });
        this.webarMarkerMeshMap.push({ markerId: "7ae5bbee-dbbe-4582-8bfa-054b5c67b12d", markerMesh: webarMarkerMesh2 });
        this.appManager.modelManager.root.setParent(webarMarkerMesh1);
        this.appManager.modelManager.root.position.x = 0
        this.appManager.modelManager.root.position.y = 0
        this.appManager.modelManager.root.position.z = 0
        sphere.setParent(webarMarkerMesh2);
        sphere.position.x = 0
        sphere.position.y = 0
        sphere.position.z = 0
    }

    checkDeviceCompatibility(){
        this.initialise()
        this.viewInARButton = document.getElementById("view-in-AR-btn-image")
        this.viewInARButton.onclick = () => this.toggleSession()
        this.viewInARButton.classList.remove("hide")

    }

    async toggleSession(){
        try {
            WEBARSDK.InitBabylonJs(document.getElementById("renderCanvas"), this.appManager.scene, this.appManager.cameraManager.camera, this.webarMarkerMeshMap);
        }
        catch(err) {
            console.log(err)
            this.supported = false;
            this.appManager.htmlElements.snackBar.open(); 
            this.appManager.htmlElements.snackBar.labelText = "AR Mode is not supported on your device."
            return;
        }
    }

    placeModel(){
        this.hitTest.transformationMatrix.decompose(undefined, this.appManager.productManager.root.rotationQuaternion, this.appManager.productManager.root.position)
        this.appManager.productManager.root.scaling = new BABYLON.Vector3(1,1,-1)
        this.modelPlaced = true
        this.resetModelButton.classList.remove("hide")
    }
    resetModel(){
        this.appManager.productManager.root.position = new BABYLON.Vector3(0,0,0)
        this.appManager.productManager.root.scaling = new BABYLON.Vector3(1,1,-1)
        this.modelPlaced = false
    }
    hideModel(){
        this.appManager.productManager.root.position = new BABYLON.Vector3(0,0,0)
        this.appManager.productManager.root.scaling = new BABYLON.Vector3(0,0,0)
        this.resetModelButton.classList.add("hide")
        this.modelPlaced = false
    }
}