import {MDCDrawer} from "@material/drawer";
import {MDCTabBar} from '@material/tab-bar';
import {MDCRipple} from '@material/ripple';
import { ContentOption } from "./contentOption"

export class ContentDrawer{

    constructor(appManager){
        this.appManager = appManager
        this.baseElement3D = document.getElementById('contentOption3D')
        this.baseElement2D = document.getElementById('contentOption2D')
        this.contentOptions = []

        this.initialise()
    }

    initialise(){
        const drawer = MDCDrawer.attachTo(document.querySelector('.mdc-drawer'))
        this.drawer = drawer
        this.menuButton = document.getElementById("menu-btn")
        this.menuButton.addEventListener('click', (event) => {
            drawer.open = true
        })
        this.setUpTabs()
        this.setUpLoadAllButton()
        this.setUpClearAllButton()
    }

    setUpTabs(){
        this.contentList3D = document.getElementById('contentList3D')
        this.contentList2D = document.getElementById('contentList2D')
        this.tabBar = new MDCTabBar(document.querySelector('.mdc-tab-bar'))

        document.getElementById('tab3D').onclick = () => this.openTab3D(true); 
        document.getElementById('tab2D').onclick = () => this.openTab3D(false); 

        this.addContentOption({url:"/textures/models/puh.glb", name:"puh.glb","contentId":1,"transform":{"positionX":1,"positionY":1,"positionZ":1,"rotationX":0,"rotationY":0,"rotationZ":0}})
        this.addContentOption({url:"/textures/Manual.pdf",name:"Manual.pdf","contentId":2,"transform":{"positionX":1,"positionY":0.5,"positionZ":0.3,"rotationX":0,"rotationY":0,"rotationZ":0}})
        this.addContentOption({url:"/textures/dromoneLogo.png",name:"dromoneLogo.png","contentId":3,"transform":{"positionX":0,"positionY":0.3,"positionZ":-0.4,"rotationX":0,"rotationY":0,"rotationZ":0}})
        this.addContentOption({url:"/textures/dromoneVideo.mp4",name:"dromoneVideo.mp4","contentId":4,"transform":{"positionX":1,"positionY":1,"positionZ":0,"rotationX":0,"rotationY":0,"rotationZ":0}})
        this.addContentOption({url:"Random text pop up for testing...",name:"text","contentId":5,"transform":{"positionX":1.2,"positionY":-0.5,"positionZ":-0.3,"rotationX":0,"rotationY":0,"rotationZ":0}})
    }

    openTab3D(value){
        if(value){
            this.contentList3D.classList.remove("hide")
            this.contentList2D.classList.add("hide")
            this.tabBar.activateTab(0);
        }else{
            this.contentList3D.classList.add("hide")
            this.contentList2D.classList.remove("hide")
            this.tabBar.activateTab(1);
        }
    }

    addContentOption(content){
        if(content.url.includes(".glb"))
            this.contentOptions.push(new ContentOption(this.appManager, content, this.baseElement3D, this.contentList3D))
        else
            this.contentOptions.push(new ContentOption(this.appManager, content, this.baseElement2D, this.contentList2D))
        if(this.loadAllButtonElement)
            this.checkIfToShowLoadAllButton()
    }

    clearAllOptions(){
        this.contentOptions.forEach(element => element.disposeElement())
        this.contentOptions = []
    }

    setUpClearAllButton(){
        this.clearAllButtonElement = document.getElementById('clearAllModelsButton')
        const clearAllButton = new MDCRipple(this.clearAllButtonElement);

        this.clearAllButtonElement.onclick = () => this.appManager.modelManager.disposeModels(); 
    }

    setUpLoadAllButton(){
        this.loadAllButtonElement = document.getElementById('loadAllModelsButton')
        const loadAllButton = new MDCRipple(this.loadAllButtonElement);

        this.loadAllButtonElement.onclick = () => this.loadAllModels(); 
    }
    loadAllModels(){
        this.contentOptions.forEach(contentOption => {
            if(contentOption.content.url.includes(".glb"))
                this.appManager.mediaPlayer.loadMedia(contentOption.content); 
        })
        this.drawer.open = false
    }
    checkIfToShowLoadAllButton(){
        var count = 0
        this.contentOptions.forEach(contentOption => {
            if(contentOption.content.url.includes(".glb"))
                count++
        })
        if(count>1)
            this.loadAllButtonElement.classList.remove("hide")
        else
            this.loadAllButtonElement.classList.add("hide")
    }
}