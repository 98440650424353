//import * as GUI from 'babylonjs-gui';
import { CameraManager } from "./cameraManager"
import { ModelManager } from "./modelManager"
import { LightManager } from "./lightManager"
import { Options } from "../Objects/options"
import { ContentDrawer } from "../Objects/contentDrawer"
import { QRScanner } from "../Objects/qrScanner"
import { XRManager } from "./xrManager"
import { XRManagerBlippar } from "./xrManagerBlippar"
import { VRManager } from "./vrManager"
import { MediaPlayer } from "../Objects/mediaPlayer"
import { AnimationControls } from "../Objects/animationControls"
import { Grid } from "../Objects/grid"
import { BabylonGrid } from "../babylon.gridMaterial.min"
import { RaycastSelector } from "../Objects/raycastSelector"
import { HotspotManager } from "./hotspotManager"


export class AppManager{  

    constructor(engine, scene, data, htmlElements){
        this.engine = engine
        this.data = data
        this.scene = scene
        this.htmlElements = htmlElements
        
        this.cameraManager = new CameraManager(this)
        this.modelManager = new ModelManager(this)
        this.lightManager = new LightManager(this)
        this.options = new Options(this)
        this.contentDrawer = new ContentDrawer(this)
        this.mediaPlayer = new MediaPlayer(this)
        this.qrScanner = new QRScanner(this)
        this.animationControls = new AnimationControls(this)
        this.xrManager = new XRManager(this)
        this.xrManagerBlippar = new XRManagerBlippar(this)
        this.vrManager = new VRManager(this)
        this.raycastSelector = new RaycastSelector(this)
        this.grid = new Grid(this)
        this.hotspotManager = new HotspotManager(this)
        
    }
}