import {MDCDialog} from '@material/dialog';

export class MediaPlayer{

    constructor(appManager){
        this.appManager = appManager

        this.initialise()
    }

    initialise(){
        this.PDFPopup = new MDCDialog(document.getElementById('PDFPopup'))
        this.TextPopup = new MDCDialog(document.getElementById('TextPopup'))
        this.MediaPopup = new MDCDialog(document.getElementById('MediaPopup'))
        this.VideoPopup = new MDCDialog(document.getElementById('VideoPopup'))

        this.iframePopup = document.getElementById('iframePopup')
        this.textPopupContainer = document.getElementById('textPopupContainer')
        this.pdfDownloadLink = document.getElementById('pdfDownloadLink')
        this.videoElement = document.getElementById('videoElement')
        this.videoSourceElement = document.getElementById('videoSourceElement')

    }

    loadMedia(content){
        let url = content.url
        if(url.includes(".glb"))
            this.loadModel(content)
        else if(url.includes(".mp4"))
            this.loadVideo(url)
        else if(url.includes(".pdf"))
            this.loadDocument(url)
        else if(url.includes(".png"))
            this.loadImage(url)
        else
            this.loadText(url)
    }

    loadModel(content){
        this.appManager.modelManager.loadNewModel(content); 
        //this.appManager.modelManager.disposeModel() 
    }
    loadText(url){
        this.textPopupContainer.innerHTML = url
        this.TextPopup.open()
    }
    loadDocument(url){
        this.pdfDownloadLink.href = url
        this.PDFPopup.open()
    }
    loadVideo(url){
        this.videoSourceElement.src = url
        this.VideoPopup.open()
        this.videoElement.load() 
        this.videoElement.pause()
        this.VideoPopup.listen('MDCDialog:closed',()=> this.videoElement.pause())
    }
    loadImage(url){
        this.iframePopup.src = url
        this.MediaPopup.open()
    }
}